import React from "react"
import styled from "styled-components"
import {
  SectionPage,
  SectionTitle,
  MarkdownContent,
  SectionButtonWrapper,
  Container,
  ExploreMore,
} from "../../components/Section"
import SectionButton from "../../components/Button/SectionButton"
import CheckIcon from "../../components/Icons/CheckIcon"
import PrimaryButton from "../../components/Button/PrimaryButton"

const Greensky = styled.div`
  max-width: 570px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap:wrap;
  justify-content:center;
  border-radius: 10px;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);

`
const GreenskyBody = styled.div`
  padding: 20px;   
  @media (min-width: 1200px) {
    padding: 30px;   
  }
  @media (min-width: 1600px) {
    padding: 40px;   
  }
`
const GreenSkyImage = styled.div`
  margin-bottom:15px;
`
const GreenSkyLead = styled.div`
  & p {
    color: #010920;
  }
`
const GreenskyOption = styled.div`
margin-bottom:30px;
`
const ItemGreensky = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 26px;
  color: #1e4156;
`
class FinanceAvailable extends React.Component {
  render() {
    const { data, modalOpen } = this.props
    return (
      <SectionPage 
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px">
        <Container>
          <SectionTitle textAlign="center">{data.title}</SectionTitle>
          <MarkdownContent
            textAlign="center"
            color="#010920"
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
          <Greensky>
            <GreenskyBody>
              <GreenSkyImage><img src={data.features[0].image} alt="green sky" /></GreenSkyImage>
              <GreenSkyLead><p>{data.features[0].description}</p></GreenSkyLead>
              <GreenskyOption>
                {data.features[0].options.map((item, i) => (
                  <ItemGreensky key={i}>
                    <CheckIcon /> {item}
                  </ItemGreensky>
                ))}
              </GreenskyOption>
              {/* <SectionButtonWrapper textAlign="left">
              <div role="button" tabIndex={0} onClick={() => modalOpen()} onKeyDown={e => { if (e.key === "Enter" || e.key === " ") { modalOpen()}}}><SectionButton>Get Started</SectionButton></div>
              </SectionButtonWrapper> */}
              <ExploreMore>
                <a href="https://www.greensky.com/prequal/gs/prequalify-for-loan?merchant=81042278&channel=External-Button-Prequal" target="_blank" className="btn-lg">
                  <PrimaryButton text="Apply Now" />
                </a>
              </ExploreMore>

            </GreenskyBody>

          </Greensky>


        </Container>
      </SectionPage>
    )
  }
}

export default FinanceAvailable
