import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick";

const ListBenefit = styled.div`  
  .slick-slide{
    > div{
      height:100%;
    }
  }
  .slick-track{
    height:160px;
    @media (min-width:768px) {
      height:200px;
    }
  }
`
const ListItemBenefit = styled.div`
  padding:0 15px;
  height:100%;
  display:flex !important;
  align-items:center;
  justify-content:center;
  border: 1px solid #98DFFF;
  background-color:#fff; 
`
const CardPrimaryOutline = styled.div`        
  padding:0 20px;
  display:flex;
  flex-wrap: nowrap;  
`
const CardIcon = styled.div`
  width:50px;
  height:50px;
  min-width:50px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0 5px;
`
const CardBody = styled.div`
  padding:0 5px;
`
const CardTitle = styled.h3`
  color:#062C44;
  margin-bottom:0;
`
const CardDesc = styled.div`
  p{
    margin-bottom:0;
    font-size:14px;
    line-height:20px;
    color:#010920;
  }
`

export default class BenefitCarousel extends Component {
  render() {
    const { data } = this.props
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (      
      <ListBenefit>
        <Slider {...settings} className='slick-benefit'>
          {
            data.map((item, i)=>{
              return(
                <ListItemBenefit key={i}>
                  <CardPrimaryOutline className='card'>
                    <CardIcon className='card-icon'><img src={item.icon} alt={item.title} /></CardIcon>
                    <CardBody className='card-body'>
                      <CardTitle className='card-title'>{item.title}</CardTitle>
                      <CardDesc className='card-desc'><p>{item.description}</p></CardDesc>
                    </CardBody>
                  </CardPrimaryOutline>
                </ListItemBenefit>
              )
            })
          }
        </Slider>
      </ListBenefit>
    );
  }
}