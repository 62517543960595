
const scrollToRef = (ref, modifier) => {
    modifier = modifier || 0;
    window.scrollTo({
        top: ref.current.offsetTop - 50,
        behavior: 'smooth'
    });
   
};

export default scrollToRef;
