import React from "react"
import {
  SectionTitle,
  MarkdownContent,
  Container,
  SectionPage,
} from "../../components/Section"
import RelatedProductCarousel from "../../components/RelatedProductCarousel"

class TopSellingProducts extends React.Component {
  render() {
    const { data, products, location } = this.props
    return (
      <SectionPage
      ept="80px"
      epb="80px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bg="#F4FBFF">
        <Container>
          <SectionTitle textAlign="center">{data.title}</SectionTitle>
          <MarkdownContent
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
          <RelatedProductCarousel data={products} location={location} />
        </Container>
      </SectionPage>
    )
  }
}

export default TopSellingProducts
